import { Component, Vue } from "vue-property-decorator";
import { telegramGroupLink, instagramGroupLink } from "@config/social";

@Component
export default class Footer extends Vue {
  private copyrightYear = 2024;

  private get telegramGroupLink() {
    return telegramGroupLink;
  }

  private get instagramGroupLink() {
    return instagramGroupLink;
  }
}
